<div class="events" style="display: table">
  <div style="display: table-row">
    <div style="display: table-cell">
      <div class="extended-color">
        <mat-icon class="event-icon" color="primary" svgIcon="hf-impulses"></mat-icon>
        {{ 'dashboard.legend.hf-impulses' | translate }}
        ({{ (nature$ | async)?.event.impulses }})
      </div>
      <div>
        <img src="./assets/images/legend/swellEvents.svg" />
        {{ 'dashboard.legend.majorSwells' | translate }}
        ({{ (nature$ | async)?.event.swells }})
      </div>
      @if ((mpType$ | async) !== 1) {
      <div>
        <img src="./assets/images/legend/majorSags.svg" />
        {{ 'dashboard.legend.major-sags' | translate }}
        ({{ (nature$ | async)?.event.sags }})
      </div>
      } @if ((mpType$ | async) === 1) {
      <div>
        <img src="./assets/images/legend/majorSags.svg" />
        {{ 'dashboard.legend.sags' | translate }}
        ({{ (nature$ | async)?.event.sags }})
      </div>
      }
      <div>
        <img src="./assets/images/legend/rvc.svg" />
        {{ 'dashboard.legend.rvc' | translate }}
        ({{ (nature$ | async)?.event.rvc }})
      </div>
      <div>
        <img src="./assets/images/legend/interruptions.svg" />
        {{ 'dashboard.legend.interruptions' | translate }}
        ({{ (nature$ | async)?.event.interruptions }})
      </div>
      <div>
        <img src="./assets/images/legend/waveshape.svg" />
        {{ 'dashboard.legend.waveshape' | translate }}
        ({{ (nature$ | async)?.event.waveshape }})
      </div>
      @if ((mpType$ | async) === 1 || (mpType$ | async) === 3) {
      <div>
        <img src="./assets/images/legend/other_events.svg" />
        {{ 'dashboard.legend.othersEvents' | translate }}
        ({{ (nature$ | async)?.event.other }})
      </div>
      }
    </div>
    <div style="display: table-cell; padding-left: 20px">
      <div class="event-toggle">
        <mat-icon class="event-icon snapshot-icon" svgIcon="circle"></mat-icon>
        {{ 'dashboard.legend.snapshots' | translate }}
        ({{ (nature$ | async)?.event.snapshot }}) @if ((nature$ | async)?.event.snapshot > 0) {
        <mat-checkbox [checked]="setToggleSnapshot" (change)="toggleSnapshotVisibility($event)"></mat-checkbox>
        }
      </div>
      @if (from === 'chart'){
      <div>
        <img class="alarm" src="./assets/images/legend/alarm-line.svg" />
        @if (smallScreen){
        {{ 'dashboard.legend.alarms' | translate }}
        } @else {
        {{ 'dashboard.legend.trendAlarms' | translate }}
        } ({{ (nature$ | async)?.alarm.alarm }})
      </div>
      <div>
        <img class="alarm" src="./assets/images/legend/warning-line.svg" />
        @if (smallScreen){
        {{ 'dashboard.legend.warnings' | translate }}
        } @else {
        {{ 'dashboard.legend.trendWarnings' | translate }}
        } ({{ (nature$ | async)?.alarm.warning }})
      </div>
      }
      <div
        [ngClass]="{
          spacer: (nature$ | async)?.event.snapshot > 0,
          'big-spacer': (nature$ | async)?.event.snapshot === 0
        }"
      ></div>
      <div class="device-msg">
        <img style="max-height: 16px; min-width: 16px" src="./assets/images/legend/restart.svg" />
        {{ 'dashboard.legend.device' | translate }}
        ({{ (nature$ | async)?.status.restart }})
      </div>
      <div class="conform">
        <mat-icon svgIcon="calendar-blank"></mat-icon>
        {{ 'dashboard.legend.notes' | translate }}
        ({{ (nature$ | async)?.note }})
      </div>
    </div>
  </div>
</div>
