<ng-template #infoDrawer>
  <div class="info-drawer">
    @if (infoEvent?.nature === 'alarm') {
    <mat-card class="mat-info-data-detail">
      <mat-card-header>
        <div class="header">
          <span class="title" data-cy="info-drawer-title">
            @if (infoEvent?.severity === 1){
            <img [src]="'./assets/images/legend/alarm-line.svg'" />
            {{ 'dashboard.legend.trendAlarm' | translate }} {{ infoEvent?.typeName }}
            } @else {
            <img [src]="'./assets/images/legend/warning-line.svg'" />
            {{ 'dashboard.legend.trendWarning' | translate }} {{ infoEvent?.typeName }}
            }
          </span>
          <div class="spacer"></div>
          <div>
            <button mat-icon-button type="button" (click)="closeDrawer()" class="close">
              <mat-icon svgIcon="close" [ngStyle]="{ color: 'black' }"></mat-icon>
            </button>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="mat-info-data-container">
          <div class="mat-info-data-stats mat-info-data-info">
            <div class="mat-info-date">
              <h4>{{ 'dashboard.detail-overlay.trigger-date' | translate }}:</h4>
              <div>{{ infoEvent?.triggeredWhen | DST : 'LL' : infoEvent?.timezone }}</div>
            </div>
            <div class="mat-info-time">
              <h4>{{ 'dashboard.detail-overlay.trigger-time' | translate }}:</h4>
              <div>{{ infoEvent?.triggeredWhen | DST : 'LT' : infoEvent?.timezone }}</div>
            </div>
            <div class="mat-info-duration">
              <h4>{{ 'dashboard.detail-overlay.duration' | translate }}:</h4>
              <div>
                {{ infoEvent.duration | eventDuration }}
              </div>
            </div>
          </div>
          <div class="mat-info-data-info">
            <h4>{{ 'dashboard.detail-overlay.event-identification' | translate }}:</h4>
            <div data-cy="info-drawer-channel">
              {{ 'dashboard.detail-overlay.channel' | translate }}: {{ infoEvent.typeName }}
            </div>
            <div>{{ 'dashboard.detail-overlay.condition' | translate }}: {{ infoEvent.description }}</div>
          </div>
          <div class="mat-info-data-footer">
            <a
              mat-button
              color="accent"
              data-cy="info-drawer-link"
              [routerLink]="['/charts', mpId, accountId, infoEvent.id]"
              target="_blank"
            >
              {{ 'dashboard.detail-overlay.viewTrendCharts' | translate }}
              <mat-icon svgIcon="launch"></mat-icon>
            </a>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    } @else if (infoData?.typeName) {
    <mat-card class="mat-info-data-detail">
      <mat-card-header>
        @if (infoData?.typeName) {
        <div class="header">
          @if (infoData?.typeName && infoIcons[infoData?.typeName]) {
          <img [src]="'./assets/images/legend/' + infoIcons[infoData?.typeName]" />
          } @else {
          <img [src]="'./assets/images/legend/other_events.svg'" />
          } @if (infoData?.defaultDisplayName) {
          <span class="title">
            {{ infoData?.defaultDisplayName }}
          </span>
          } @else { @if (mpTypeId === 1 && infoData?.nature !== 'note' ) {
          <span class="title">
            {{ 'dashboard.detail-overlay.' + infoData?.typeName + '.qubescan' | translate }}
          </span>
          } @else if (infoData?.nature === 'note') {
          <span class="title"> {{ infoData?.summary }}</span>
          } @else {
          <span class="title">
            {{
              'dashboard.detail-overlay.' + infoData?.typeName + '.title'
                | translateFallback : 'eventType.' + infoData?.typeName
            }}
          </span>
          } } @if (infoData?.isIncomplete) {
          <span class="title">&nbsp;({{ 'dashboard.detail-overlay.inProgress' | translate }})</span>
          }
          <div class="spacer"></div>
          <div>
            <button mat-icon-button type="button" (click)="closeDrawer()" class="close">
              <mat-icon svgIcon="close" [ngStyle]="{ color: 'black' }"></mat-icon>
            </button>
          </div>
        </div>
        } @if (!infoData?.typeName) {
        <mat-card-title>
          {{ 'global.loading' | translate }}
        </mat-card-title>
        }
      </mat-card-header>
      @if (infoData?.typeName) {
      <mat-card-content>
        <div class="mat-info-data-container">
          <div class="mat-info-data-stats">
            <div>
              <h4>{{ 'dashboard.detail-overlay.trigger-date' | translate }}:</h4>
              <div>{{ infoData?.tzShiftedDate }}</div>
            </div>
            @if (infoData?.eventMagnitude) {
            <div>
              <h4>{{ 'dashboard.detail-overlay.magnitude' | translate }}:</h4>
              @if (eventTypeId !== -1) {
              <div>
                {{
                  (infoData?.typeName === 'highFrequencyImpulse'
                    ? 'dashboard.detail-overlay.hf-magnitude-value'
                    : 'dashboard.detail-overlay.magnitude-value'
                  ) | translate : { value: infoData?.eventMagnitude }
                }}
              </div>
              } @else {
              <div>
                {{ infoData?.eventMagnitudeTag }}
              </div>
              }
            </div>
            }
            <div>
              <h4>{{ 'dashboard.detail-overlay.trigger-time' | translate }}:</h4>
              <div>{{ infoData?.tzShiftedTime }}</div>
            </div>
            @if (infoData?.nature !== 'note'){ @if (infoData?.nature !== 'status'){
            <div>
              <h4>{{ 'dashboard.detail-overlay.duration' | translate }}:</h4>
              @if (infoData?.duration) {
              <div>
                {{ infoData.duration | eventDuration }}
              </div>
              } @if (!infoData?.duration) {
              <div>
                {{ 'global.not-applicable' | translate }}
              </div>
              }
            </div>
            } @else {
            <div>
              <h4>{{ 'dashboard.detail-overlay.type' | translate }}:</h4>
              <div>{{ infoData?.typeName }}</div>
            </div>
            } @if (infoData?.nature !== 'status'){ @if (infoData?.typeName !== 'voltageSag' && infoData?.typeName !==
            'interruption') {
            <div>
              <h4>{{ 'dashboard.detail-overlay.trigger-channel' | translate }}:</h4>
              <div>{{ infoData?.channel }}</div>
            </div>
            } @else {
            <div>
              <h4>{{ 'eventGraph.eventOrigin' | translate }}:</h4>
              @if (infoData?.sagDirectionPrediction) {
              <div>{{ infoData?.sagDirectionPrediction }} {{ infoData?.sagDirectionProbability }}</div>
              } @else {
              <div>N/A</div>
              }
            </div>
            } } }
          </div>
          @if (infoIcons[infoData?.typeName] && infoData?.nature !== 'status' && infoData?.nature !== 'note') {
          <div class="mat-info-data-info">
            <h4>{{ 'dashboard.detail-overlay.event-identification' | translate }}:</h4>
            <div
              [innerHTML]="'dashboard.detail-overlay.' + infoData?.typeName + '.event-identification' | translate"
            ></div>
            @if (eventTypeId !== 2) {
            <h4>{{ 'dashboard.detail-overlay.impact' | translate }}:</h4>
            <div [innerHTML]="'dashboard.detail-overlay.' + infoData?.typeName + '.impact' | translate"></div>
            }
          </div>
          } @else {
          <div class="mat-info-data-info">
            <h4>{{ 'dashboard.detail-overlay.description' | translate }}:</h4>
            <div>
              {{ infoData?.description }}
            </div>
          </div>
          } @if (infoData?.nature !== 'status' && infoData?.nature !== 'note') {
          <div class="mat-info-data-footer">
            @if ( ((mpTypeId === 3 && (isPartner || isSysAdmin)) || mpTypeId !== 3) && infoData.gifDocumentExists === 1
            ) {
            <a
              mat-button
              color="accent"
              [routerLink]="[
                '/event-graph',
                mpId,
                accountId,
                infoData?.id,
                infoData?.typeId,
                infoData?.originalTriggeredWhen
              ]"
              target="_blank"
            >
              {{ 'dashboard.detail-overlay.view-event-graph' | translate }}
              <mat-icon svgIcon="launch"></mat-icon>
            </a>
            }
            <br />
            @if (eventTypeId !== -1 && eventTypeId !== 2 && eventTypeId !== 53 && eventTypeId !== 29) {
            <a mat-button color="accent" [routerLink]="['/more-information', infoData?.typeName]" target="_blank">
              {{ 'dashboard.detail-overlay.more-info' | translate }}
              <mat-icon svgIcon="launch"></mat-icon>
            </a>
            }
          </div>
          }
        </div>
      </mat-card-content>
      }
    </mat-card>
    } @else {
    <mat-card class="notes-card">
      <mat-card-header>
        <mat-card-title class="align-center">
          <mat-icon class="calendar-padding" svgIcon="calendar-blank"></mat-icon>
          {{ infoData.summary }}
          <div class="spacer"></div>
          <div>
            <button (click)="closeDrawer()" class="close" mat-icon-button>
              <mat-icon svgIcon="close"></mat-icon>
            </button>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="mat-info-data-container">
          <div class="mat-info-data-notes">
            <div>
              <h4>{{ 'notes.new.date' | translate }}</h4>
              <div>{{ infoData.tzShiftedDate }}</div>
            </div>
            <div>
              <h4>{{ 'notes.new.time' | translate }}</h4>
              <div>{{ infoData.tzShiftedTime }}</div>
            </div>
          </div>
        </div>
        <div class="mat-info-data-info">
          <h4>{{ 'notes.new.details' | translate }}</h4>
          <div>{{ infoData.details }}</div>
        </div>
      </mat-card-content>
    </mat-card>
    }
    <!--<div
      class="drawer-connector-overlay"
      [style.left]="infoEvent?.chartX + 'px'"
    ></div>-->
    @if (loadingInfoDrawer) {
    <div class="info-drawer-overlay">
      <mat-progress-spinner mode="indeterminate" color="primary" diameter="48"></mat-progress-spinner>
    </div>
    }
  </div>
</ng-template>
<ng-template #trendAlarmList>
  <div class="info-drawer">
    <mat-card class="mat-info-data-detail">
      <mat-card-header>
        <div class="header">
          <mat-card-title data-cy="info-drawer-title"> Trend Alarms </mat-card-title>
          <div class="spacer"></div>
          <div>
            <button mat-icon-button type="button" (click)="closeDrawer()" class="close">
              <mat-icon svgIcon="close" [ngStyle]="{ color: 'black' }"></mat-icon>
            </button>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content>
        @for (alarm of infoEvent.records; track alarm) {
        <div class="trend-alarm-name" data-cy="info-drawer-channel">
          @if (alarm?.severity === 1){
          <img [src]="'./assets/images/legend/alarm-line.svg'" />
          } @else {
          <img [src]="'./assets/images/legend/warning-line.svg'" />
          }
          <a (click)="selectAlarm(alarm)" data-cy="trend-alarm-link">{{ alarm.typeName }}</a>
        </div>
        }
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
