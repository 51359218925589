import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { GroupAccount } from 'src/app/_shared/interface/notification-group/group-account.interface';
import { SelectMpSelected } from 'src/app/_shared/interface/select-mp-selected.interface';

@Component({
  selector: 'app-select-mp',
  templateUrl: './select-mp.component.html',
  styleUrl: './select-mp.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMpComponent implements OnChanges, OnInit {
  @Input() checklistSelection = new SelectionModel<GroupAccount>(true /* multiple */);
  @Input() treeControl = new NestedTreeControl<GroupAccount>((node) => node.children);
  @Input() selected: SelectMpSelected = {
    customer: [],
    mp: [],
  };
  @Input() accountId;
  @Input() partners;
  @Input() isCustomer;
  @Input() displayBasicAccount;
  @Input() displayMsg: boolean;
  @Input() translateConfig;

  mpList = [];
  customerList = [];
  newTree = new MatTreeNestedDataSource<any>();

  hasChild = (_: number, node: GroupAccount): Boolean => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.mpList = this.selected.mp;
    this.customerList = this.selected.customer;
    if (this.partners?.length === 1 && this.partners?.[0].accountId === undefined) {
      this.setCustomerTree();
    } else {
      this.setTree();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.accountId && !changes.accountId.firstChange) {
      if (this.partners.length === 1 && this.partners[0].accountId === undefined) {
        this.setCustomerTree();
      } else {
        this.setTree();
      }
    }
  }

  setTree(): void {
    let newTree = [];
    let customerListIndex = [];
    let mpListIndex = [];
    let index = 0;
    let customerIndex = 0;
    this.partners
      ?.filter((partner) => partner?.accountId === this.accountId)
      .forEach((partner) => {
        partner?.customers
          ?.filter((customer) => customer?.subscriptionType === 'PREMIUM' || this.displayBasicAccount)
          .forEach((customer) => {
            if (customer.measurementPoints.length > 0) {
              if (this.customerList.indexOf(customer.accountId) !== -1) {
                customerListIndex.push(customerIndex);
              }
              newTree.push({
                type: 'customer',
                name: customer.accountName,
                id: customer.accountId,
                children: [],
              });

              customer?.measurementPoints.forEach((mp, iMp) => {
                if (this.mpList.indexOf(mp.measurementPointId) !== -1) {
                  mpListIndex.push({ customer: customerIndex, measurementPoint: iMp });
                }

                newTree[index].children.push({
                  type: 'mp',
                  id: mp.measurementPointId,
                  name: `${mp.locationName} | ${mp.mpId}`,
                  parent: customer.accountId,
                });
              });
              index++;
              customerIndex++;
            }
          });
      });

    this.newTree.data = newTree;

    customerListIndex.forEach((element) => {
      this.checklistSelection.toggle(this.newTree.data[element]);
      this.newTree.data[element].children.forEach((child) => {
        this.checklistSelection.toggle(child);
      });
    });

    mpListIndex.forEach((element) => {
      this.checklistSelection.toggle(this.newTree.data[element.customer].children[element.measurementPoint]);
    });
  }

  setCustomerTree(): void {
    let newTree = [];
    let mpListIndex = [];
    let index = 0;
    let customerIndex = 0;
    this.partners[0].customers
      .filter((customer) => {
        return (
          customer?.accountId === this.accountId &&
          (customer.subscriptionType === 'PREMIUM' || this.displayBasicAccount)
        );
      })
      .forEach((customer) => {
        customer?.measurementPoints.forEach((mp, iMp) => {
          if (this.mpList.indexOf(mp.measurementPointId) !== -1) {
            mpListIndex.push({ measurementPoint: iMp });
          }
          newTree.push({
            type: 'mp',
            id: mp.measurementPointId,
            name: `${mp.locationName} | ${mp.mpId}`,
          });
        });
        index++;
        customerIndex++;
      });
    this.newTree.data = newTree;

    mpListIndex.forEach((element) => {
      this.checklistSelection.toggle(this.newTree.data[element.measurementPoint]);
    });
  }

  selectAll(): void {
    this.checklistSelection.select(...this.newTree.data);
    if (this.newTree.data[0].children) {
      let customerId = this.newTree.data.map((node) => {
        if (node.children) {
          this.checklistSelection.select(...node.children);
        }
        return node.id;
      });
      this.selected.customer = [...customerId];
      this.selected.mp = [];
    } else {
      let customerId = this.newTree.data.map((node) => node.id);
      this.selected.mp = [...customerId];
      this.selected.customer = [];
    }
  }

  descendantsAllSelected(node: GroupAccount): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every((child) => {
      return this.checklistSelection.isSelected(child);
    });
  }

  descendantsPartiallySelected(node: GroupAccount): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  customerToggle(event: MatCheckboxChange, node: GroupAccount): void {
    if (this.descendantsPartiallySelected(node) || this.descendantsAllSelected(node)) {
      if (this.checklistSelection.isSelected(node)) {
        this.checklistSelection.toggle(node);
        if (this.selected[node.type].indexOf(node.id) !== -1) {
          this.selected[node.type].splice(this.selected[node.type].indexOf(node.id), 1);
        }
      }

      const descendants = this.treeControl.getDescendants(node);
      this.checklistSelection.deselect(...descendants);

      node.children.forEach((child) => {
        if (this.selected[child.type].indexOf(child.id) !== -1) {
          this.selected[child.type].splice(this.selected[child.type].indexOf(child.id), 1);
        }
      });

      event.source.indeterminate = false;
      event.source.checked = false;
    } else {
      if (this.selected[node.type].indexOf(node.id) !== -1) {
        this.selected[node.type].splice(this.selected[node.type].indexOf(node.id), 1);
      } else if (this.selected[node.children[0].type].indexOf(node.children[0].id) !== -1) {
        this.selected[node.children[0].type].splice(
          this.selected[node.children[0].type].indexOf(node.children[0].id),
          1
        );
      } else {
        this.selected[node.type].push(node.id);
        node.children.forEach((child) => {
          if (this.selected[child.type].indexOf(child.id) !== -1) {
            this.selected[child.type].splice(this.selected[child.type].indexOf(child.id), 1);
          }
        });
      }

      this.checklistSelection.toggle(node);
      this.treeControl;
      const descendants = this.treeControl.getDescendants(node);

      this.checklistSelection.isSelected(node)
        ? this.checklistSelection.select(...descendants)
        : this.checklistSelection.deselect(...descendants);
    }
  }

  selectMp(node: GroupAccount): void {
    this.checklistSelection.toggle(node);
    let parent = this.newTree.data.find((parent) => parent.id === node.parent);
    if (!this.checklistSelection.isSelected(node)) {
      if (parent?.children?.length === 1) {
        this.checklistSelection.toggle(parent);
        if (parent?.id && this.selected['customer'].indexOf(parent.id) !== -1) {
          this.selected['customer'].splice(this.selected['customer'].indexOf(parent.id), 1);
        }
      } else if (parent?.children?.every((child) => !this.checklistSelection.isSelected(child))) {
        if (this.checklistSelection.isSelected(parent)) {
          this.checklistSelection.toggle(parent);
        }
      }
      if (this.selected['mp'].indexOf(node.id) !== -1) {
        this.selected['mp'].splice(this.selected['mp'].indexOf(node.id), 1);
      }
      if (parent?.id && this.selected['customer'].indexOf(parent.id) !== -1) {
        this.selected['customer'].splice(this.selected['customer'].indexOf(parent.id), 1);
        parent?.children?.forEach((child) => {
          if (child.id !== node.id) {
            this.selected['mp'].push(child.id);
          }
        });
      }
    } else {
      if (!this.checklistSelection.isSelected(parent)) {
        this.checklistSelection.toggle(parent);
      }
      if (parent?.children?.every((child) => this.checklistSelection.isSelected(child))) {
        if (!this.checklistSelection.isSelected(parent)) {
          this.checklistSelection.toggle(parent);
        }
      }
      this.selected['mp'].push(node.id);
    }
  }
}
