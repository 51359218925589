<div class="list-layout-content">
  <div class="heading">
    <h1>{{ text.title | translate }}</h1>
  </div>
  <div>
    <div class="create-button">
      <button mat-flat-button color="accent" [routerLink]="routerLinkArg" [appEventTracking]="tracking" data-cy='list-layout-create-button'>
        {{ text.createButton | translate }}
      </button>
    </div>
    @if (dataSource?.data?.length > 0) {
    <div>
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="setSortEvent($event)" #sort>
        @for (column of displayedColumns; track column) {
        <ng-container matColumnDef="{{ column }}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders?.[column]?.title | translate }}</th>
          @if(column !== 'isDisabled' && column !== 'action') { @if(columnHeaders?.[column]?.pipe === 'date') {
          <td mat-cell *matCellDef="let row">
            {{ row[column] | DST : columnHeaders?.[column]?.pipeParams }}
          </td>
          } @else {
          <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
          } } @else if (column === 'isDisabled') {
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              [disableRipple]="true"
              [checked]="row.isDisabled === 0"
              (change)="enableGroupEvent(row, $event)"
            >
            </mat-checkbox>
          </td>
          } @else if (column === 'action') {
          <td mat-cell *matCellDef="let row">
            <button
              mat-icon-button
              color="accent"
              (click)="delete(row[action.id], row.name)"
              appEventTracking="action.delete.tracking"
              [trackingParams]="{ id: row[action.id] }"
            >
              <mat-icon data-cy="delete-icon">delete</mat-icon>
            </button>
            <button
              mat-icon-button
              color="accent"
              [routerLink]="[action.edit.routerLink[0], row[action.id]]"
              appEventTracking="action.edit.tracking"
              [trackingParams]="{ id: row[action.id] }"
            >
              <mat-icon data-cy="edit-icon">edit</mat-icon>
            </button>
          </td>
          }
        </ng-container>
        }
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [length]="listLenght"
        [pageSizeOptions]="[20, 40, 60, 100]"
        showFirstLastButtons
        (page)="onPageChange($event)"
      ></mat-paginator>
    </div>
    } @else {
    <p>{{ text.noData | translate }}</p>
    }
  </div>
</div>
