<div class="replace-pqube-table">
  <div class="test">
    <h5>
      {{ 'replacePqube.fewStep' | translate }}
    </h5>
    <button
      mat-flat-button
      color="accent"
      [disabled]="step !== null && step?.isCompleted === 0"
      (click)="startReplacement()"
    >
      {{ 'replacePqube.downloadIni' | translate }}
    </button>
    <mat-icon
      class="arrow-down"
      [ngClass]="{ disabled: step?.steps[0].step === 4 }"
      [svgIcon]="'arrow-down-bold'"
    ></mat-icon>
    <button
      mat-flat-button
      [disabled]="step?.steps[0].step !== 1 || step?.isCompleted === 1"
      color="accent"
      (click)="disassociate()"
    >
      <mat-icon [svgIcon]="'link-variant-off'"></mat-icon>{{ 'global.disassociate' | translate }}
    </button>
    <mat-icon class="arrow-down" [svgIcon]="'arrow-down-bold'"></mat-icon>
    <mat-checkbox
      class="confirm-replace-checkbox"
      data-cy="replace-checkbox"
      [ngClass]="step?.steps[0].step !== 2 ? 'disabled' : ''"
      [disabled]="step?.steps[0].step !== 2 || step?.isCompleted === 1"
      [checked]="step?.steps[0].step > 2"
      (change)="confirmInstall()"
      >{{ 'replacePqube.confirm' | translate }}</mat-checkbox
    >
    <mat-icon
      class="arrow-down"
      [ngClass]="{ disabled: step?.steps[0].step < 3 }"
      [svgIcon]="'arrow-down-bold'"
    ></mat-icon>
    <button
      mat-flat-button
      color="accent"
      [disabled]="step?.steps[0].step !== 3 || step?.isCompleted === 1"
      (click)="openAssociation()"
    >
      {{ 'replacePqube.replacementToken' | translate }}
    </button>
    @if (step?.steps[0].step === 4) {
    <span>
      <mat-icon
        class="arrow-down"
        [ngClass]="{ disabled: step?.steps[0].step < 4 }"
        [svgIcon]="'arrow-down-bold'"
      ></mat-icon>
      <mat-icon [svgIcon]="'information'"></mat-icon>
      {{ 'replacePqube.completed' | translate }}
    </span>
    }
  </div>
</div>
