import { cloneDeep } from 'lodash';
import { take } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromStore from 'src/app/_store/_reducers';
import { CustomColumns } from 'src/app/_shared/interface/fleet-pref.interface';
import { UserPreferences } from 'src/app/_shared/interface/user-preferences.interface';
import { AuthService } from 'src/app/_shared/services';

@Component({
  selector: 'app-table-display-setting',
  templateUrl: './table-display-setting.component.html',
  styleUrl: './table-display-setting.component.scss',
})
export class TableDisplaySettingComponent implements OnInit {
  @Input() columns: any[] = [];
  customColumnsPref: CustomColumns;

  constructor(private store: Store<fromStore.State>, private authService: AuthService) {}

  ngOnInit(): void {
    this.columns.forEach(
      (col) =>
        (this.customColumnsPref = {
          ...this.customColumnsPref,
          [col.id]: col.default,
        })
    );
    this.store
      .select(fromStore.getUserPreferences)
      .pipe(take(1))
      .subscribe((userPrefs) => {
        if (userPrefs?.fleetView?.customColumns) {
          // Initialize component with user pref
          this.customColumnsPref = cloneDeep(userPrefs.fleetView.customColumns);
        }
      });
  }

  onColumnCheckboxSelected(column: string, checked: boolean): void {
    // Set new value and propagate the changes to parent component
    this.customColumnsPref[column] = checked;
    this.store
      .pipe(select(fromStore.getUserPreferences))
      .pipe(take(1))
      .subscribe((userPrefs) => {
        let userPrefsClone: UserPreferences = cloneDeep(userPrefs);
        if (!userPrefsClone?.fleetView?.customColumns) {
          userPrefsClone = {
            ...userPrefsClone,
            fleetView: {
              customColumns: {
                [column]: checked,
              },
            },
          };
        } else {
          userPrefsClone.fleetView.customColumns[column] = checked;
        }
        if (userPrefsClone !== userPrefs) {
          this.authService.savePreferences({ ...userPrefsClone }, true);
        }
      });
  }
}
