import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss'],
})
export class RangePickerComponent implements OnChanges, OnInit {
  @Input() selectedDateRange: DateRange<Date> | null;
  @Input() error: string;
  @Input() rangeType: string;
  @Input() timezone;
  @Input() uiConfig = {
    showCancelButton: true,
  };
  @Output() applyDateRange = new EventEmitter();
  @Output() emitCancel = new EventEmitter();

  savedDateRange: DateRange<Date>;
  dateSelectorForm: FormGroup;
  maxDate = new Date();
  today = new Date();
  disableNextButton = true;
  lastMonth = new Date();
  format: string;
  dateDelimiter1: number;
  dateDelimiter2: number;
  dayIndex: number;
  monthIndex: number;
  yearIndex: number;
  is24hFormat: boolean;
  smallScreen: boolean = false;

  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;
  @ViewChild('calendar2', { static: false }) calendar2: MatCalendar<Date>;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.smallScreen = window.innerWidth < 992;
  }

  constructor(private _dateAdapter: DateAdapter<Date>, private changeDetectorRef: ChangeDetectorRef) {
    let timePattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    this.dateSelectorForm = new FormGroup({
      startDate: new FormControl(),
      startTime: new FormControl('00:00', [Validators.pattern(timePattern)]),
      endDate: new FormControl(),
      endTime: new FormControl('23:59', [Validators.pattern(timePattern)]),
    });
  }

  ngOnInit(): void {
    this.onResize();
    this.format = moment().creationData().locale.longDateFormat('L');
    this.dateDelimiter1 = this.format.indexOf('/');
    this.dateDelimiter2 = this.format.indexOf('/', this.dateDelimiter1 + 1);
    this.dayIndex = this.format.indexOf('D');
    this.monthIndex = this.format.indexOf('M');
    this.yearIndex = this.format.indexOf('Y');

    if (this.rangeType !== 'global') {
      if (this.smallScreen) {
        this.lastMonth.setDate(1);
        this.lastMonth.setMonth(this.lastMonth.getMonth());
      } else {
        this.lastMonth.setDate(1);
        this.lastMonth.setMonth(this.lastMonth.getMonth() - 1);
      }
    }

    if (this.selectedDateRange) {
      if (this.rangeType === 'global') {
        if (
          moment(this.selectedDateRange.end)
            .startOf('month')
            .isSame(moment(this.selectedDateRange.start).startOf('month')) &&
          moment(this.selectedDateRange.end).isAfter(moment().startOf('month'))
        ) {
          if (this.smallScreen) {
            this.lastMonth = this._dateAdapter.createDate(
              parseInt(moment(this.selectedDateRange.start).tz(this.timezone, true).format('YYYY')),
              moment(this.selectedDateRange.start).tz(this.timezone, true).format('MM') === '01'
                ? 0
                : parseInt(moment(this.selectedDateRange.start).tz(this.timezone, true).format('MM')),
              1
            );
          } else {
            this.lastMonth = this._dateAdapter.createDate(
              parseInt(moment(this.selectedDateRange.start).tz(this.timezone, true).format('YYYY')),
              moment(this.selectedDateRange.start).tz(this.timezone, true).format('MM') === '01'
                ? 11
                : parseInt(moment(this.selectedDateRange.start).tz(this.timezone, true).format('MM')) - 2,
              1
            );
          }
        } else {
          this.lastMonth = this._dateAdapter.createDate(
            parseInt(moment(this.selectedDateRange.start).tz(this.timezone, true).format('YYYY')),
            moment(this.selectedDateRange.start).tz(this.timezone, true).format('MM') === '01'
              ? 0
              : parseInt(moment(this.selectedDateRange.start).tz(this.timezone, true).format('MM')) - 1,
            1
          );
        }
        this.today = this._dateAdapter.createDate(
          moment(this.selectedDateRange.start).tz(this.timezone, true).format('MM') === '12'
            ? parseInt(moment(this.selectedDateRange.start).tz(this.timezone, true).format('YYYY')) + 1
            : parseInt(moment(this.selectedDateRange.start).tz(this.timezone, true).format('YYYY')),
          moment(this.selectedDateRange.start).tz(this.timezone, true).format('MM') === '12'
            ? 0
            : parseInt(moment(this.selectedDateRange.start).tz(this.timezone, true).format('MM')),
          1
        );

        if (this.calendar && this.calendar2) {
          if (
            (!this.smallScreen &&
              moment(this.calendar2.activeDate).year() === new Date().getFullYear() &&
              moment(this.calendar2.activeDate).month() === new Date().getMonth()) ||
            (this.smallScreen &&
              moment(this.calendar.activeDate).year() === new Date().getFullYear() &&
              moment(this.calendar.activeDate).month() === new Date().getMonth())
          ) {
            this.disableNextButton = true;
          } else {
            this.disableNextButton = false;
          }
        } else {
          if (
            moment(this.selectedDateRange.start).year() === new Date().getFullYear() &&
            (moment(this.selectedDateRange.start).month() + 1 === new Date().getMonth() ||
              moment(this.selectedDateRange.start).month() === new Date().getMonth())
          ) {
            this.disableNextButton = true;
          } else {
            this.disableNextButton = false;
          }
        }
      }

      this.dateSelectorForm.get('startDate').setValue(moment(this.selectedDateRange.start).format('L'));
      this.dateSelectorForm.get('endDate').setValue(moment(this.selectedDateRange.end).format('L'));
      this.dateSelectorForm.get('startTime').setValue(moment(this.selectedDateRange.start).format('HH:mm'));
      this.dateSelectorForm.get('endTime').setValue(moment(this.selectedDateRange.end).format('HH:mm'));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDateRange && !changes.selectedDateRange.firstChange && this.rangeType === 'global') {
      if (changes.selectedDateRange.currentValue.start.diff(moment(), 'months') !== 0) {
        this.calendar.activeDate = this._dateAdapter.createDate(
          changes.selectedDateRange.currentValue.start.format('YYYY'),
          changes.selectedDateRange.currentValue.start.format('MM') - 1,
          changes.selectedDateRange.currentValue.start.format('DD')
        );
        if (!this.smallScreen) {
          this.calendar2.activeDate = this._dateAdapter.createDate(
            changes.selectedDateRange.currentValue.start.format('MM') === '12'
              ? parseInt(changes.selectedDateRange.currentValue.start.format('YYYY')) + 1
              : changes.selectedDateRange.currentValue.start.format('YYYY'),
            changes.selectedDateRange.currentValue.start.format('MM') === '12'
              ? '0'
              : changes.selectedDateRange.currentValue.start.format('MM'),
            changes.selectedDateRange.currentValue.start.format('DD')
          );
        }
      } else {
        if (!this.smallScreen) {
          this.calendar.activeDate = this._dateAdapter.createDate(
            parseInt(moment().format('YYYY')),
            parseInt(moment().format('MM')) - 2,
            parseInt(moment().format('DD'))
          );
          this.calendar2.activeDate = this._dateAdapter.createDate(
            parseInt(moment().format('YYYY')),
            parseInt(moment().format('MM')) - 1,
            parseInt(moment().format('DD'))
          );
        } else {
          this.calendar.activeDate = this._dateAdapter.createDate(
            parseInt(moment().format('YYYY')),
            parseInt(moment().format('MM')) - 1,
            parseInt(moment().format('DD'))
          );
        }
      }

      if (
        (!this.calendar && !this.calendar2) ||
        (!this.smallScreen &&
          this.calendar2 &&
          moment(this.calendar2.activeDate).year() === new Date().getFullYear() &&
          moment(this.calendar2.activeDate).month() === new Date().getMonth()) ||
        (this.smallScreen &&
          this.calendar &&
          moment(this.calendar.activeDate).year() === new Date().getFullYear() &&
          moment(this.calendar.activeDate).month() === new Date().getMonth())
      ) {
        this.disableNextButton = true;
      } else {
        this.disableNextButton = false;
      }

      this.changeDetectorRef.markForCheck();
      this.dateSelectorForm.get('startDate').setValue(moment(changes.selectedDateRange.currentValue.start).format('L'));
      this.dateSelectorForm.get('endDate').setValue(moment(changes.selectedDateRange.currentValue.end).format('L'));
      this.dateSelectorForm
        .get('startTime')
        .setValue(moment(changes.selectedDateRange.currentValue.start).format('HH:mm'));
      this.dateSelectorForm.get('endTime').setValue('23:59');

      this.changeDetectorRef.detectChanges();
    } else {
      //Apply date changes to the form
      this.dateSelectorForm.get('startDate').setValue(moment(this.selectedDateRange.start).format('L'));
      this.dateSelectorForm.get('endDate').setValue(moment(this.selectedDateRange.end).format('L'));
      this.dateSelectorForm.get('startTime').setValue(moment(this.selectedDateRange.start).format('HH:mm'));
      this.dateSelectorForm.get('endTime').setValue(moment(this.selectedDateRange.end).format('HH:mm'));
    }
  }

  onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange?.start &&
      (date > this.selectedDateRange?.start || moment(date).isSame(this.selectedDateRange?.start, 'day')) &&
      !this.selectedDateRange?.end
    ) {
      this.dateSelectorForm.get('endDate').setValue(moment(date).format('L'));
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        this._dateAdapter.parse(
          this.dateSelectorForm.get('endDate').value + ' ' + this.dateSelectorForm.get('endTime').value,
          'L LT'
        )
      );
    } else {
      this.dateSelectorForm.get('endDate').setValue(null);
      this.dateSelectorForm.get('startDate').setValue(moment(date).format('L'));
      this.selectedDateRange = new DateRange(
        this._dateAdapter.parse(
          this.dateSelectorForm.get('startDate').value + ' ' + this.dateSelectorForm.get('startTime').value,
          'L LT'
        ),
        null
      );
    }
  }

  focusInput(): void {
    if (
      document.activeElement.getAttribute('tabindex') > '0' &&
      document.activeElement.getAttribute('tabindex') < '6'
    ) {
      (
        document.querySelectorAll(
          '[tabindex="' + (parseInt(document.activeElement.getAttribute('tabindex')) + 1) + '"]'
        )[0] as HTMLElement
      ).focus();
    } else {
      (document.querySelectorAll('[tabindex="1"]')[0] as HTMLElement).focus();
    }
  }

  nextMonth($event): void {
    this.calendar.activeDate = this._dateAdapter.addCalendarMonths(this.calendar.activeDate, +1);
    if (!this.smallScreen) {
      this.calendar2.activeDate = this._dateAdapter.addCalendarMonths(this.calendar2.activeDate, +1);
    }
    this.changeDetectorRef.markForCheck();

    if (
      (!this.smallScreen &&
        moment(this.calendar2.activeDate).year() === new Date().getFullYear() &&
        moment(this.calendar2.activeDate).month() === new Date().getMonth()) ||
      (this.smallScreen &&
        moment(this.calendar.activeDate).year() === new Date().getFullYear() &&
        moment(this.calendar.activeDate).month() === new Date().getMonth())
    ) {
      this.disableNextButton = true;
    }
  }

  previousMonth(): void {
    this.disableNextButton = false;

    this.calendar.activeDate = this._dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
    if (!this.smallScreen) {
      this.calendar2.activeDate = this._dateAdapter.addCalendarMonths(this.calendar2.activeDate, -1);
    }
    this.changeDetectorRef.markForCheck();
  }

  checkValidityDate($event, calendar: number, input): void {
    let keepCursorPos = input.selectionStart;

    let dateCheck = null;
    if (calendar === 1) {
      dateCheck = this.dateSelectorForm.get('startDate');
    } else {
      dateCheck = this.dateSelectorForm.get('endDate');
    }

    if (isNaN($event.data)) {
      dateCheck.setValue(dateCheck.value.substring(0, keepCursorPos - 1) + dateCheck.value.substring(keepCursorPos));
      keepCursorPos--;
    } else if (keepCursorPos === 11) {
      dateCheck.setValue(dateCheck.value.substring(0, keepCursorPos - 1));
    } else {
      if (input.selectionStart !== dateCheck.value.length) {
        if ($event.inputType === 'deleteContentBackward') {
          if (input.selectionStart === this.yearIndex) {
            dateCheck.setValue(
              dateCheck.value.substring(0, this.yearIndex + 1) + '0' + dateCheck.value.substring(this.yearIndex + 1)
            );
          } else if (input.selectionStart === this.yearIndex + 1) {
            dateCheck.setValue(
              dateCheck.value.substring(0, this.yearIndex + 1) + '0' + dateCheck.value.substring(this.yearIndex + 1)
            );
          } else if (input.selectionStart === this.yearIndex + 2) {
            dateCheck.setValue(
              dateCheck.value.substring(0, this.yearIndex + 2) + '0' + dateCheck.value.substring(this.yearIndex + 2)
            );
          } else if (input.selectionStart === this.yearIndex + 3) {
            dateCheck.setValue(
              dateCheck.value.substring(0, this.yearIndex + 3) + '0' + dateCheck.value.substring(this.yearIndex + 3)
            );
          } else if (input.selectionStart === this.dateDelimiter1) {
            dateCheck.setValue(
              dateCheck.value.substring(0, this.dateDelimiter1 - 1) +
                '0/' +
                dateCheck.value.substring(this.dateDelimiter1)
            );
          } else if (input.selectionStart === this.monthIndex) {
            dateCheck.setValue(
              dateCheck.value.substring(0, this.monthIndex) + '0' + dateCheck.value.substring(this.monthIndex)
            );
          } else if (input.selectionStart === this.monthIndex + 1) {
            dateCheck.setValue(
              dateCheck.value.substring(0, this.monthIndex + 1) + '1' + dateCheck.value.substring(this.monthIndex + 1)
            );
          } else if (input.selectionStart === this.dateDelimiter2) {
            dateCheck.setValue(
              dateCheck.value.substring(0, this.dateDelimiter2 - 1) +
                '1/' +
                dateCheck.value.substring(this.dateDelimiter2)
            );
          } else if (input.selectionStart === this.dayIndex) {
            if (dateCheck.value.substring(this.dayIndex, this.dayIndex + 1) === '0') {
              dateCheck.setValue(
                dateCheck.value.substring(0, this.dayIndex) + '01' + dateCheck.value.substring(this.dayIndex + 1)
              );
            } else {
              dateCheck.setValue(
                dateCheck.value.substring(0, this.dayIndex) + '0' + dateCheck.value.substring(this.dayIndex)
              );
            }
          }
        } else {
          if (input.selectionStart === this.dateDelimiter1 || input.selectionStart === this.dateDelimiter2) {
            dateCheck.setValue(
              dateCheck.value.substring(0, input.selectionStart - 1) + dateCheck.value.substring(input.selectionStart)
            );
          } else {
            dateCheck.setValue(
              dateCheck.value.substring(0, input.selectionStart) + dateCheck.value.substring(input.selectionStart + 1)
            );
          }
        }
      }

      if (
        keepCursorPos === this.yearIndex + 1 &&
        dateCheck.value.substring(this.yearIndex, this.yearIndex + 1) >
          new Date().getFullYear().toString().substring(0, 1)
      ) {
        dateCheck.setValue(
          dateCheck.value.substring(0, keepCursorPos - 1) + new Date().getFullYear().toString().substring(0, 1)
        );
      } else if (
        keepCursorPos === this.yearIndex + 2 &&
        dateCheck.value.substring(this.yearIndex, this.yearIndex + 1) ===
          new Date().getFullYear().toString().substring(0, 1) &&
        dateCheck.value.substring(this.yearIndex + 1, this.yearIndex + 2) >
          new Date().getFullYear().toString().substring(1, 2)
      ) {
        dateCheck.setValue(
          dateCheck.value.substring(0, this.yearIndex + 1) +
            new Date().getFullYear().toString().substring(1, 2) +
            dateCheck.value.substring(this.yearIndex + 2)
        );
      } else if (
        keepCursorPos === this.yearIndex + 3 &&
        dateCheck.value.substring(this.yearIndex, this.yearIndex + 1) ===
          new Date().getFullYear().toString().substring(0, 1) &&
        dateCheck.value.substring(this.yearIndex + 1, this.yearIndex + 2) ===
          new Date().getFullYear().toString().substring(1, 2) &&
        dateCheck.value.substring(this.yearIndex + 2, this.yearIndex + 3) >
          new Date().getFullYear().toString().substring(2, 3)
      ) {
        dateCheck.setValue(
          dateCheck.value.substring(0, this.yearIndex + 2) +
            new Date().getFullYear().toString().substring(2, 3) +
            dateCheck.value.substring(this.yearIndex + 3)
        );
      } else if (
        keepCursorPos === this.yearIndex + 4 &&
        dateCheck.value.substring(this.yearIndex, this.yearIndex + 1) ===
          new Date().getFullYear().toString().substring(0, 1) &&
        dateCheck.value.substring(this.yearIndex + 1, this.yearIndex + 2) ===
          new Date().getFullYear().toString().substring(1, 2) &&
        dateCheck.value.substring(this.yearIndex + 2, this.yearIndex + 3) ===
          new Date().getFullYear().toString().substring(2, 3) &&
        dateCheck.value.substring(this.yearIndex + 3, this.yearIndex + 4) >
          new Date().getFullYear().toString().substring(3, 4)
      ) {
        dateCheck.setValue(
          dateCheck.value.substring(this.yearIndex, this.yearIndex + 3) +
            new Date().getFullYear().toString().substring(3, 4) +
            '/' +
            dateCheck.value.substring(this.yearIndex + 5)
        );
        keepCursorPos++;
      } else if (keepCursorPos === this.monthIndex + 1 && $event.data > 1) {
        dateCheck.setValue(
          dateCheck.value.substring(0, keepCursorPos - 1) +
            '0' +
            $event.data +
            '/' +
            dateCheck.value.substring(keepCursorPos + 2)
        );
        keepCursorPos += 2;
      } else if (
        keepCursorPos === this.monthIndex + 1 &&
        $event.data > 0 &&
        dateCheck.value.substring(this.monthIndex + 1, this.monthIndex + 2) > 2
      ) {
        dateCheck.setValue(
          dateCheck.value.substring(0, keepCursorPos - 1) + '12' + dateCheck.value.substring(keepCursorPos + 1)
        );
        keepCursorPos += 2;
      } else if (
        keepCursorPos === this.monthIndex + 2 &&
        dateCheck.value.substring(this.monthIndex, this.monthIndex + 1) === '1' &&
        $event.data > 2
      ) {
        dateCheck.setValue(
          dateCheck.value.substring(0, keepCursorPos - 1) + '2/' + dateCheck.value.substring(keepCursorPos + 1)
        );
        keepCursorPos++;
      } else if (
        keepCursorPos === this.monthIndex + 2 &&
        dateCheck.value.substring(this.monthIndex, this.monthIndex + 1) === '0' &&
        $event.data === '0'
      ) {
        dateCheck.setValue(
          dateCheck.value.substring(0, keepCursorPos - 1) + '1/' + dateCheck.value.substring(keepCursorPos + 1)
        );
        keepCursorPos++;
      } else if (keepCursorPos === this.dayIndex + 1) {
        if (
          $event.data > 3 ||
          (dateCheck.value.substring(this.monthIndex, this.monthIndex + 2) === '02' && $event.data > 2)
        ) {
          dateCheck.setValue(dateCheck.value.substring(0, keepCursorPos - 1) + '0' + $event.data + '/');
          keepCursorPos += 2;
        } else if ($event.data === '3' && dateCheck.value.substring(this.dayIndex + 1, this.dayIndex + 2) > 1) {
          dateCheck.setValue(
            dateCheck.value.substring(0, keepCursorPos) +
              this.getLastDayMonth(dateCheck.value.substring(this.monthIndex, this.monthIndex + 2))
          );
          keepCursorPos++;
        }
      } else if (
        keepCursorPos === this.dayIndex + 2 &&
        dateCheck.value.substring(this.dayIndex, this.dayIndex + 1) === '0' &&
        $event.data === '0'
      ) {
        dateCheck.setValue(dateCheck.value.substring(0, keepCursorPos - 1) + '1');
      } else if (
        keepCursorPos === this.dayIndex + 2 &&
        dateCheck.value.substring(this.dayIndex, this.dayIndex + 1) === '3' &&
        $event.data > this.getLastDayMonth(dateCheck.value.substring(this.monthIndex, this.monthIndex + 2)) &&
        $event.inputType !== 'deleteContentBackward'
      ) {
        dateCheck.setValue(
          dateCheck.value.slice(0, -1) +
            this.getLastDayMonth(dateCheck.value.substring(this.monthIndex, this.monthIndex + 2)) +
            '/'
        );
        keepCursorPos++;
      } else if (keepCursorPos === this.dayIndex + 2 && $event.inputType !== 'deleteContentBackward') {
        dateCheck.setValue(
          dateCheck.value.substring(0, keepCursorPos - 1) +
            $event.data +
            '/' +
            dateCheck.value.substring(keepCursorPos + 1)
        );
        keepCursorPos++;
      } else if (keepCursorPos === this.dateDelimiter1 || keepCursorPos === this.dateDelimiter2) {
        if ($event.inputType === 'deleteContentBackward') {
          if (keepCursorPos === dateCheck.value.length) {
            dateCheck.setValue(dateCheck.value.slice(0, -1));
          } else {
            dateCheck.setValue(
              dateCheck.value.substring(0, keepCursorPos - 1) + '1' + dateCheck.value.substring(keepCursorPos)
            );
            keepCursorPos--;
          }
        } else {
          dateCheck.setValue(
            dateCheck.value.substring(0, keepCursorPos) + '/' + dateCheck.value.substring(keepCursorPos + 1)
          );
          keepCursorPos++;
        }
      } else if (
        keepCursorPos === 10 &&
        dateCheck.value.substring(this.monthIndex, this.monthIndex + 2) === '02' &&
        dateCheck.value.substring(this.dayIndex, this.dayIndex + 2) === '29' &&
        new Date(dateCheck.value.substring(this.yearIndex, this.yearIndex + 4), 1, 29).getDate() === 1
      ) {
        dateCheck.setValue(
          dateCheck.value.substring(0, this.dayIndex + 1) + '8' + dateCheck.value.substring(this.dayIndex + 2)
        );
      }
    }
    input.selectionStart = input.selectionEnd = keepCursorPos;
  }

  checkValidityTime($event, calendar: number, input): void {
    let keepCursorPos = input.selectionStart;

    let timeCheck;
    if (calendar === 1) {
      timeCheck = this.dateSelectorForm.get('startTime');
    } else {
      timeCheck = this.dateSelectorForm.get('endTime');
    }

    if (input.selectionStart !== timeCheck.value.length) {
      if ($event.inputType === 'deleteContentBackward') {
        if (input.selectionStart === 0) {
          timeCheck.setValue('0' + timeCheck.value);
        } else if (input.selectionStart === 1) {
          timeCheck.setValue(timeCheck.value.substring(0, 1) + '0' + timeCheck.value.substring(1));
        } else if (input.selectionStart === 2) {
          timeCheck.setValue(timeCheck.value.substring(0, 1) + '0:' + timeCheck.value.substring(2));
          keepCursorPos--;
        } else if (input.selectionStart === 3) {
          timeCheck.setValue(timeCheck.value.substring(0, 3) + '0' + timeCheck.value.substring(3));
        } else if (input.selectionStart === 4) {
          timeCheck.setValue(timeCheck.value.substring(0, 4) + '0' + timeCheck.value.substring(4));
        }
      } else {
        timeCheck.setValue(
          timeCheck.value.substring(0, input.selectionStart) + timeCheck.value.substring(input.selectionStart + 1)
        );
      }
    }

    if (isNaN($event.data)) {
      timeCheck.setValue(timeCheck.value.substring(0, keepCursorPos - 1) + timeCheck.value.substring(keepCursorPos));
      keepCursorPos--;
    } else if (keepCursorPos === 6) {
      timeCheck.setValue(timeCheck.value.substring(0, keepCursorPos - 1));
    } else if (keepCursorPos === 1 && $event.data > 2) {
      timeCheck.setValue('23:' + timeCheck.value.substring(3, 5));
      keepCursorPos += 2;
    } else if (keepCursorPos === 2) {
      if (timeCheck.value.substring(0, 1) === '2' && $event.data > 3) {
        timeCheck.setValue(timeCheck.value.substring(0, keepCursorPos - 1) + '3:' + timeCheck.value.substring(3, 5));
      } else if ($event.inputType === 'deleteContentBackward') {
        timeCheck.setValue(timeCheck.value.substring(0, keepCursorPos - 1));
      } else {
        timeCheck.setValue(timeCheck.value.substring(0, keepCursorPos) + ':' + timeCheck.value.substring(3, 5));
      }
      keepCursorPos++;
    } else if (keepCursorPos === 3) {
      timeCheck.setValue(timeCheck.value.substring(0, 2) + ':' + timeCheck.value.substring(3, 5));
    } else if (keepCursorPos === 4 && $event.data > 5) {
      timeCheck.setValue(timeCheck.value.substring(0, keepCursorPos - 1) + '59');
      keepCursorPos++;
    }

    input.selectionStart = input.selectionEnd = keepCursorPos;
  }

  onFocusOutDate(calendar: number): void {
    if (calendar === 1 && this.dateSelectorForm.get('startDate').value.length === 10) {
      this.selectedDateRange = new DateRange(
        this._dateAdapter.parse(
          this.dateSelectorForm.get('startDate').value + ' ' + this.dateSelectorForm.get('startTime').value,
          'L LT'
        ),
        this.selectedDateRange?.end
      );
    } else if (calendar === 2 && this.dateSelectorForm.get('endDate').value.length === 10) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange?.start,
        this._dateAdapter.parse(
          this.dateSelectorForm.get('endDate').value + ' ' + this.dateSelectorForm.get('endTime').value,
          'L LT'
        )
      );
    }
  }

  saveDateRange(): void {
    this.savedDateRange = this.selectedDateRange;
    this.applyDateRange.emit(this.savedDateRange);
  }

  cancelDateRange(): void {
    this.selectedDateRange = this.savedDateRange;
    this.dateSelectorForm = new FormGroup({
      startDate: new FormControl(),
      startTime: new FormControl('00:00'),
      endDate: new FormControl(),
      endTime: new FormControl('12:00'),
    });
    this.emitCancel.emit(true);
  }

  private getLastDayMonth(month: string): number {
    if (
      month === '01' ||
      month === '03' ||
      month === '05' ||
      month === '07' ||
      month === '08' ||
      month === '10' ||
      month === '12'
    ) {
      return 1;
    } else if (month === '04' || month === '06' || month === '09' || month === '11') {
      return 0;
    }
  }
}
