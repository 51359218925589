<form (keydown.tab)="$event.preventDefault(); focusInput(); $event.stopPropagation()" [formGroup]="dateSelectorForm">
  <div class="date-range" (click)="$event.stopPropagation()">
    <div class="first-month">
      <div class="previous-month">
        <button mat-icon-button class="previous-month-button" (click)="previousMonth()">
          <mat-icon svgIcon="arrow-left" color="accent"></mat-icon>
        </button>
        <span>{{ calendar.activeDate | date : 'MMMM yyyy' }}</span>
        @if (smallScreen){
        <button
          class="next-month-button"
          mat-icon-button
          (click)="nextMonth($event)"
          color="accent"
          [disabled]="disableNextButton"
        >
          <mat-icon svgIcon="arrow-right"></mat-icon>
        </button>
        }
      </div>
      <mat-calendar
        #calendar
        [startAt]="lastMonth"
        (selectedChange)="onSelectedChange($event)"
        [selected]="selectedDateRange"
        [maxDate]="maxDate"
      >
      </mat-calendar>
      <span class="date-range-label">{{ 'datetimeRange.start' | translate }}</span>
      <mat-form-field class="date-field">
        <input
          #startDateInput
          matInput
          class="start-date-input"
          type="text"
          name="startDate"
          formControlName="startDate"
          placeholder="{{ format }}"
          autocomplete="off"
          tabindex="1"
          (input)="checkValidityDate($event, 1, startDateInput)"
          (focusout)="onFocusOutDate(1)"
        />
      </mat-form-field>
      <mat-form-field class="time-field">
        <input
          #startTimeInput
          matInput
          type="text"
          name="startTime"
          formControlName="startTime"
          placeholder="00:00"
          autocomplete="off"
          tabindex="2"
          (input)="checkValidityTime($event, 1, startTimeInput)"
          (focusout)="onFocusOutDate(1)"
        />
      </mat-form-field>
      @if(smallScreen){
      <span class="date-range-label">{{ 'datetimeRange.end' | translate }}</span>
      <mat-form-field class="date-field" appearance="fill">
        <input
          #endDateInput
          matInput
          type="text"
          name="endDate"
          formControlName="endDate"
          placeholder="{{ format }}"
          autocomplete="off"
          tabindex="3"
          (input)="checkValidityDate($event, 2, endDateInput)"
          (focusout)="onFocusOutDate(2)"
      /></mat-form-field>
      <mat-form-field class="time-field">
        <input
          #endTimeInput
          matInput
          type="text"
          name="endTime"
          formControlName="endTime"
          placeholder="00:00"
          autocomplete="off"
          tabindex="4"
          (input)="checkValidityTime($event, 2, endTimeInput)"
          (focusout)="onFocusOutDate(2)"
        />
      </mat-form-field>
      }
    </div>
    @if (!smallScreen){
    <div class="second-month">
      <div class="next-month">
        <span>{{ calendar2.activeDate | date : 'MMMM yyyy' }}</span>
        <button
          class="next-month-button"
          mat-icon-button
          (click)="nextMonth($event)"
          color="accent"
          [disabled]="disableNextButton"
        >
          <mat-icon svgIcon="arrow-right"></mat-icon>
        </button>
      </div>
      <mat-calendar
        #calendar2
        [startAt]="today"
        (selectedChange)="onSelectedChange($event)"
        [selected]="selectedDateRange"
        [maxDate]="maxDate"
      ></mat-calendar>
      <span class="date-range-label">{{ 'datetimeRange.end' | translate }}</span>
      <mat-form-field class="date-field" appearance="fill">
        <input
          #endDateInput
          matInput
          type="text"
          name="endDate"
          formControlName="endDate"
          placeholder="{{ format }}"
          autocomplete="off"
          tabindex="3"
          (input)="checkValidityDate($event, 2, endDateInput)"
          (focusout)="onFocusOutDate(2)"
      /></mat-form-field>
      <mat-form-field class="time-field">
        <input
          #endTimeInput
          matInput
          type="text"
          name="endTime"
          formControlName="endTime"
          placeholder="00:00"
          autocomplete="off"
          tabindex="4"
          (input)="checkValidityTime($event, 2, endTimeInput)"
          (focusout)="onFocusOutDate(2)"
      /></mat-form-field>
    </div>
    }
  </div>
  <hr />
  @if (error) {
  <span class="error-msg">{{ error }}</span>
  } @if (dateSelectorForm.get('startTime').hasError('pattern') || dateSelectorForm.get('endTime').hasError('pattern')) {
  <span class="error-pattern">
    <mat-error>{{ 'datetimeRange.errors.time' | translate }}</mat-error>
  </span>
  }
  <div class="button-section">
    @if(uiConfig.showCancelButton){
    <button class="range-picker-cancel" mat-flat-button tabindex="5" (click)="cancelDateRange()">
      {{ 'global.cancel' | translate }}
    </button>
    }
    <button
      class="range-picker-save"
      mat-flat-button
      tabindex="6"
      color="accent"
      data-cy="apply-datetime"
      [disabled]="!dateSelectorForm.valid"
      (click)="saveDateRange()"
    >
      {{ 'datetimeRange.apply' | translate }}
    </button>
  </div>
</form>
