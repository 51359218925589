import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import * as moment from 'moment';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTabGroup } from '@angular/material/tabs';
import { DateAdapter } from '@angular/material/core';

import * as fromStore from 'src/app/_store/_reducers';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { RangeSelectorActions } from 'src/app/_store/_range-selector/actions';

@Component({
  selector: 'app-range-selection',
  templateUrl: './range-selection.component.html',
  styleUrls: ['./range-selection.component.scss'],
})
export class RangeSelectionComponent extends OnDestroyMixin implements OnChanges, OnDestroy, OnInit {
  @Input() absoluteStartDate: moment.Moment;
  @Input() havePast: boolean;
  @Input() rangeType: string;

  @ViewChild('picker', { static: false }) calendar: MatCalendar<Date>;
  @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;
  @ViewChild(MatMenuTrigger) rangeMenu: MatMenuTrigger;

  maxDate: moment.Moment;
  title: string;
  type: string;
  haveFutur = false;
  timezone: string;
  scope: moment.unitOfTime.DurationConstructor;
  date: moment.Moment;
  selectedDateRange: DateRange<moment.Moment>;
  selected;
  selectedIndex = 1;
  commissionedWhen: String;
  error: string;
  hintIsOpened: boolean = true;
  showHint: boolean;

  constructor(
    private store: Store<fromStore.State>,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private _dateAdapter: DateAdapter<Date>
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .pipe(select(fromStore.getMp))
      .pipe(take(1))
      .subscribe((mp) => {
        this.timezone = mp.timezone;
      });

    this.store
      .pipe(select(fromStore.getRangeSelectorState))
      .pipe(untilComponentDestroyed(this))
      .subscribe((rangeSelector) => {
        if (
          this.type !== rangeSelector[this.rangeType].type ||
          this.scope !== rangeSelector[this.rangeType].scope ||
          this.title !== rangeSelector[this.rangeType].title
        ) {
          this.scope = rangeSelector[this.rangeType].scope;
          this.title = rangeSelector[this.rangeType].title;

          this.type = rangeSelector[this.rangeType].type;
          if (this.type === 'period') {
            this.selectedIndex = 0;
          } else {
            this.selectedIndex = 1;
          }
          this.commissionedWhen = rangeSelector[this.rangeType].commissionedWhen;

          this.selectedDateRange = new DateRange(
            moment(rangeSelector[this.rangeType].startDateRange, 'L LT'),
            moment(rangeSelector[this.rangeType].endDateRange, 'L LT')
          );

          this.setDatepickerStartView();
          this.changeDetectorRef.detectChanges();
        }
      });

    if (this.rangeType === 'report') {
      this.store
        .pipe(select(fromStore.getRangeSelectorReportPeriod))
        .pipe(untilComponentDestroyed(this))
        .subscribe((period) => {
          if (period.endDate !== null && period.startDate !== null) {
            if (this.scope === 'weeks') {
              this.selected = new DateRange(moment(period.startDate), moment(period.endDate));
            } else {
              this.selected = new Date(period.endDate);
            }

            this.checkIfFutur();
          }
        });
    } else if (this.rangeType === 'global') {
      this.store
        .pipe(select(fromStore.getRangeSelectorGlobalPeriod))
        .pipe(untilComponentDestroyed(this))
        .subscribe((period) => {
          if (period.endDate !== null && period.startDate !== null) {
            if (this.scope === 'weeks') {
              this.selected = new DateRange(moment(period.startDate, 'L'), moment(period.endDate, 'L'));
            } else {
              this.selected = new Date(moment(period.endDate, 'L').toISOString());
            }
            this.checkIfFutur();
          }
        });
    }

    this.store
      .select((state) => state.user.userPreferences.hint?.rangeSelection)
      .pipe(untilComponentDestroyed(this))
      .subscribe((showHint) => {
        this.showHint = showHint === undefined ? true : showHint;
      });

    this.store
      .pipe(select(fromStore.getHint))
      .pipe(untilComponentDestroyed(this))
      .subscribe((hint) => {
        this.hintIsOpened =
          hint?.firmwareUpdate || hint?.notificationCenter || hint?.firmwareAndNotification ? false : true;
      });

    this.maxDate = moment();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.scope && !changes.scope.firstChange) {
      this.setDatepickerStartView();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  openSelector(event): void {
    let element = document.querySelector('.mat-mdc-tab-body-content[style*="visibility: hidden"]');
    if (element) {
      this.renderer.setStyle(element, 'visibility', 'visible');
      this.renderer.setStyle(element, 'display', 'block');
    }
    this.tab.realignInkBar();
    if (this.type === 'period') {
      this.setDatepickerStartView();

      if (this.scope === 'weeks') {
        this.calendar.activeDate = this._dateAdapter.parse(this.selected.end, 'L');
      } else {
        this.calendar.activeDate = this._dateAdapter.parse(this.selected, 'L');
      }
    }
  }

  updateDate(event): void {
    this.store.dispatch(
      RangeSelectorActions.setWeekDay({
        payload: {
          rangeType: this.rangeType,
          year: event.year(),
          month: event.month(),
          day: event.date(),
        },
      })
    );
    this.rangeMenu.closeMenu();
  }

  chosenYearHandler(normalizedYear: moment.Moment): void {
    if (this.scope === 'years') {
      this.rangeMenu.closeMenu();
      this.store.dispatch(
        RangeSelectorActions.setYear({
          payload: {
            rangeType: this.rangeType,
            year: normalizedYear.year(),
          },
        })
      );
    }
  }

  chosenMonthHandler(normalizedMonth: moment.Moment): void {
    if (this.scope === 'months') {
      this.rangeMenu.closeMenu();
      this.store.dispatch(
        RangeSelectorActions.setMonth({
          payload: {
            rangeType: this.rangeType,
            year: normalizedMonth.year(),
            month: normalizedMonth.month(),
          },
        })
      );
    }
  }

  changeTab(event): void {
    this.selectedIndex = event.index;
    this.type = event.tab.textLabel;
    if (this.type === 'period') {
      this.setDatepickerStartView();
    } else {
      this.type = 'range';
    }
    this.store.dispatch(
      RangeSelectorActions.changeType({
        payload: { rangeType: this.rangeType, type: this.type, timezone: this.timezone },
      })
    );
  }

  applyDateRange(dateRange): void {
    if (dateRange.start.isAfter(moment())) {
      this.error = 'Start date is outside the valid range';
    } else if (dateRange.end.isAfter(moment().endOf('day'))) {
      this.error = 'End date is outside the valid range';
    } else if (dateRange.end.isBefore(dateRange.start)) {
      this.error = 'Start date should be before end date';
    } else {
      this.error = null;
      this.store.dispatch(
        RangeSelectorActions.setRange({
          payload: { rangeType: this.rangeType, startDate: dateRange.start, endDate: dateRange.end },
        })
      );
      this.rangeMenu.closeMenu();
    }
  }

  private checkIfFutur(): void {
    if (
      this.selected.end
        ? moment(this.selected.end.valueOf()).add(1, 'day').isAfter(moment().tz(this.timezone))
        : moment(this.selected.valueOf()).add(1, 'day').isAfter(moment().tz(this.timezone))
    ) {
      this.haveFutur = false;
    } else {
      this.haveFutur = true;
    }
  }

  private setDatepickerStartView(): void {
    if (this.calendar) {
      if (this.scope === 'days' || this.scope === 'weeks') {
        this.calendar.currentView = 'month';
      } else if (this.scope === 'months') {
        this.calendar.currentView = 'year';
      } else if (this.scope === 'years') {
        this.calendar.currentView = 'multi-year';
      }
    }
  }
}
