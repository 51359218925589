import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-list-layout',
  templateUrl: './list-layout.component.html',
  styleUrl: './list-layout.component.scss',
})
export class ListLayoutComponent {
  @Input() text;
  @Input() routerLinkArg: Array<string>;
  @Input() tracking: string;
  @Input() dataSource;
  @Input() displayedColumns;
  @Input() columnHeaders;
  @Input() action;
  @Input() listLenght;
  @Output() setSort = new EventEmitter();
  @Output() enableGroup = new EventEmitter();
  @Output() deleteEmitter = new EventEmitter();
  @Output() pageChangeEmitter = new EventEmitter();

  setSortEvent(event): void {
    this.setSort.emit(event);
  }

  enableGroupEvent(row, $event): void {
    this.enableGroup.emit({ group: row, event: $event });
  }

  delete(id: number, name: string): void {
    this.deleteEmitter.emit({ id, name });
  }

  onPageChange(event: PageEvent): void {
    this.pageChangeEmitter.emit(event);
  }
}
