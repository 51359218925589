import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';

@Component({
  selector: 'app-datetime-range-picker',
  templateUrl: './datetime-range-picker.component.html',
  styleUrls: ['./datetime-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimeRangePickerComponent {
  @Input() dateRange: DateRange<Date> | null;

  @Input() dateRangeDisplayFormat: string = 'YYYY-MM-dd (HH:mm)';
  @Input() timezone: string = null;
  @Output() applyDateRange = new EventEmitter();

  constructor() {}

  setRange(event): void {
    this.dateRange = new DateRange(event.start, event.end);
    this.applyDateRange.emit(event);
  }
}
