<button data-cy="options-menu" class="display-options" mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon svgIcon="tune-variant" color="accent"></mat-icon>
</button>

<mat-menu #menu="matMenu">
  <div class="display-options-menu">
    @for(column of columns; track column) {
    <button mat-menu-item (click)="$event.stopPropagation()">
      <mat-checkbox
        [attr.data-cy]="column['data-cy']"
        (change)="onColumnCheckboxSelected(column.id, $event.checked)"
        [checked]="customColumnsPref[column.id]"
        >{{ column.translate | translate }}</mat-checkbox
      >
    </button>
    }
  </div>
</mat-menu>
