<div>
  @if (displayMsg){
  <h4>{{ translateConfig.selectOne | translate }}</h4>
  }
  <button class="select-all-button" mat-flat-button color="accent" (click)="selectAll()">
    {{ 'global.selectAll' | translate }}
  </button>
  <mat-tree [dataSource]="newTree" [treeControl]="treeControl" class="example-tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <mat-checkbox [checked]="checklistSelection.isSelected(node)" (change)="selectMp(node)">
        {{ node.name }}
      </mat-checkbox>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodeToggle>
      <div class="mat-tree-node">
        <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name" [attr.data-cy]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-checkbox
          [checked]="checklistSelection.isSelected(node) || descendantsAllSelected(node)"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="customerToggle($event, node)"
          (click)="$event.stopPropagation()"
        >
          {{ node.name }} ({{ node.children.length }})
        </mat-checkbox>
        @if(selected[node.type].includes(node.id) && displayMsg) {
        <span class="info-auto-add-member">
          <mat-icon color="accent" svgIcon="information" />
          {{ translateConfig.autoAdd | translate }}
        </span>
        }
      </div>
      <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</div>
