<div class="datetime-range-box" [matMenuTriggerFor]="contactMenu">
  <span class="datetime-range-display">
    <span class="datetime-range-start">
      {{ dateRange ? (dateRange.start | date : dateRangeDisplayFormat) : 'Start date' }}</span
    >
    <span>></span>
    <span class="datetime-range-end">{{ dateRange ? (dateRange.end | date : dateRangeDisplayFormat) : 'End date' }}</span>
  </span>
  <button class="datetime-range-button" mat-icon-button (click)="$event.preventDefault()" data-cy="datetime-picker">
    <mat-icon color="primary" svgIcon="calendar"></mat-icon>
  </button>
</div>

<mat-menu #contactMenu="matMenu">
  <app-range-picker
    [selectedDateRange]="dateRange"
    [timezone]="timezone"
    [rangeType]="'create-report'"
    (applyDateRange)="setRange($event)"
  ></app-range-picker>
</mat-menu>
